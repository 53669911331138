import {
  MEMBERS_AREA_DEF_ID,
  experiments,
  PageId,
} from '../constants';
import {getBookingsDefId} from './editor-sdk-actions';

export async function installMembersArea(sdk, appToken) {
  sdk.application.install(appToken, {
    appDefinitionId: MEMBERS_AREA_DEF_ID,
    initiatorAppDefinitionId: await getBookingsDefId(sdk)
  })
}

export async function addBookingsMembersArea(sdk, appToken) {
  const membersAPI = sdk.application.getPublicAPI(appToken, {appDefinitionId: MEMBERS_AREA_DEF_ID});
  const appDefinitionId = await getBookingsDefId(sdk);

    return membersAPI.addApplications([
      {
        appDefinitionId: appDefinitionId,
        pageId: PageId.BOOKINGS_MEMBERS_AREA,
        social: false,
        showInLoginMenu: true,
        method: 'addSection',
      },
    ]);

}
